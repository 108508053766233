<template>
    <div class="container">
        <div class="header-btn">
            <div>
                <el-button type="primary" @click="outerVisible = true">添加班结</el-button>
                <el-dialog v-model="outerVisible" title="添加班结" center>
                    <template #default>
                        <el-card shadow="never" class="card-border">
                            <template #header>
                                <div class="card-header">
                                    <span>添加新班结</span>
                                </div>
                            </template>
                            <div class="card-content">
                                <el-form-item label="分店:" style="width:292px">
                                    <el-select placeholder="全部" clearable>
                                        <el-option :value="0" label="分店1" />
                                        <el-option :value="1" label="分店2" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="终端:" style="width:292px">
                                    <el-select placeholder="全部" clearable>
                                        <el-option :value="0" label="终端1" />
                                        <el-option :value="1" label="终端2" />
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-card>
                    </template>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="outerVisible = false">取消</el-button>
                            <el-button type="primary" @click="innerVisible = true">
                                确定
                            </el-button>
                        </div>
                    </template>
                </el-dialog>
            </div>
        </div>
    </div>
    <div class="content">
        <el-card shadow="never" class="card-border">
            <template #header>
                <div class="card-header">
                    <span>查询</span>
                </div>
            </template>
            <div>
                <el-form :inline="true" label-width="100px" label-position="right">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="分店:">
                                <el-select placeholder="全部" clearable>
                                    <el-option :value="0" label="分店1" />
                                    <el-option :value="1" label="分店2" />
                                    <el-option :value="2" label="分店3" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="终端:">
                                <el-select placeholder="全部" clearable>
                                    <el-option :value="0" label="终端1" />
                                    <el-option :value="1" label="终端2" />
                                    <el-option :value="2" label="终端3" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10">
                        </el-col>
                        <el-col :span="4">
                            <div class="search-btn">
                                <el-button type="primary">查询</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
    </div>
    <div class="detail">
        <el-card shadow="never" class="card-border">
            <template #header>
                <div class="card-header">
                    <span>订单明细</span>
                </div>
            </template>
            <div>
                <el-table stripe border style="width: 100%" fit>
                    <el-table-column prop="out_trade_no" label="班结号">
                    </el-table-column>
                    <el-table-column prop="trade_no" label="终端号">
                    </el-table-column>
                    <el-table-column prop="shanghu" label="分店">
                    </el-table-column>
                    <el-table-column prop="fendian" label="总订单量">
                    </el-table-column>
                    <el-table-column prop="created_at_fat" label="总金额">
                    </el-table-column>
                    <el-table-column prop="money" label="退款订单量">
                    </el-table-column>
                    <el-table-column prop="huodong" label="退款金额">
                    </el-table-column>
                    <el-table-column prop="huodong" label="开始时间">
                    </el-table-column>
                    <el-table-column prop="huodong" label="截止时间">
                    </el-table-column>
                    <el-table-column prop="youhui" label="操作">
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination :page-size="20" :pager-count="7" layout="prev, pager, next" :total="10">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const outerVisible = ref(false)
const innerVisible = ref(false)
</script>

<style lang="less" scoped>
.container{
    .header-btn{
        .card-border {
            border-top: 5px solid #e7e7e7;
            margin-top: 10px;

            .card-header {
                font-size: 15px;
                font-weight: bold;
            }

            .card-content {
                margin-top: 20px;
            }
        }

        .dialog-footer button:first-child {
            margin-right: 10px;
        }
    }

}
.content {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

    }
}
.detail {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
        }
    }

}
</style>